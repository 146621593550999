/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "order_id",
    label: "交科运单号",
    type: "input",
    iw: 200,
    props: {
      placeholder: "请输入"
    }
  },
  {
    field: "external_id",
    label: "网络货运单号",
    type: "input",
    iw: 200,
    props: {
      placeholder: "请输入"
    }
  },
  {
    field: ["ge_end_time", "le_end_time"],
    type: "dateRange",
    label: "结束指令时间",
    iw: 320
  }
];
