<template>
  <div class="page-main-box" id="ManualSyncBox">
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          :dataSource="tableDataSource"
          @checkbox-all="selectAllEvent"
          @checkbox-change="selectChangeEvent"
          height="auto"
        >
          <p-t-column type="checkbox" width="60" fixed="left"></p-t-column>
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column field="id" min-width="262" title="交科运单号" />
          <p-t-column
            field="external_id"
            min-width="262"
            title="网络货运单号
"
          />
          <p-t-column
            field="instruct_end_time"
            min-width="170"
            title="结束指令时间"
          >
            <template v-slot="{ row }">
              <span>{{
                row.instruct_end_time
                  ? formatToDateTime(
                      row.instruct_end_time,
                      "yyyy-MM-dd hh:mm:ss"
                    )
                  : ""
              }}</span>
            </template>
          </p-t-column>
          <template #footer>
            <a-button
              class="mt-12 ml-32"
              @click="handleSync"
              :disabled="!selectOrderList.length"
              type="primary"
              :loading="isLoading"
              >同步</a-button
            >
          </template>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRaw, onMounted } from "vue";
import { message } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import {
  useEtcInvoiceOrderPageApi,
  useEtcInvoiceSyncInvoiceApi
} from "@/apis/etc";
import useSearchForm from "@/hooks/UseSearchForm";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import useTableHeight from "@/hooks/useTableHeight";

export default {
  name: "manual-sync",
  components: {},
  setup() {
    const etcInvoiceOrderPageApi = useEtcInvoiceOrderPageApi();
    const etcInvoiceSyncInvoiceApi = useEtcInvoiceSyncInvoiceApi();

    // table高度
    const tableHeight = ref();
    const isLoading = ref(false);

    let schemaList = searchHeaderOptions;

    const { filter, handleFormSearch } = useSearchForm(schemaList);

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const getList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          external_id: filter.value.external_id
            ? [filter.value.external_id]
            : [],
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(etcInvoiceOrderPageApi(data));
        return useTableDataRecords(res);
      }
    });

    const selectOrderList = ref([]);

    const selectAllEvent = ({ checked, records }) => {
      selectOrderList.value = records;
      console.log(checked ? "所有勾选事件" : "所有取消事件", records);
    };

    const selectChangeEvent = ({ checked, records }) => {
      selectOrderList.value = records;
      console.log(checked ? "勾选事件" : "取消事件", records);
    };

    // 同步操作
    const handleSync = async () => {
      isLoading.value = true;
      const list = selectOrderList.value.map(item => {
        return item.id;
      });
      const [, res] = await $await(
        etcInvoiceSyncInvoiceApi({ order_ids: list })
      );
      if (res) {
        isLoading.value = false;
        message.success("同步成功");
        getList();
      } else {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      tableDataSource,
      handleFormSearch,
      schema: searchHeaderOptions,
      formatToDateTime,
      selectAllEvent,
      selectOrderList,
      selectChangeEvent,
      handleSync,
      isLoading
    };
  }
};
</script>

<style lang="less" scoped>
#ManualSyncBox {
  position: relative;
  overflow: hidden;
}
</style>
